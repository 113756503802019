import React from "react";
import "./App.css";
import OrgChart from "@unicef/react-org-chart";
import companyLogo from "./assets/LURINLOGO.png";
// import { tree, tree1, tree2, tree3, tree4 } from "./Tree";
import { tree1, tree2, tree3, tree4 } from "./Tree";
import avatarPersonnel from "./assets/avatar-personnel.svg";
import { Link } from "react-router-dom";

export default class OrgChartLURIN extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tree: null,
      downloadingChart: false,
      config: {},
      // highlightPostNumbers: [1],
    };
  }
  componentDidUpdate(prevProps) {
    const { tree: propTree } = this.props;

    // Typical usage (don't forget to compare props):

    if (propTree !== prevProps.tree) {
      this.setState({
        tree: propTree ? JSON.parse(JSON.stringify(propTree)) : null,
      });
    }
  }
  getChild = async (id) => {
    return this.props.getChilds(id);
  };

  handleDownload = () => {
    this.setState({ downloadingChart: false });
  };

  handleOnChangeConfig = (config) => {
    this.setState({ config: config });
  };

  handleLoadConfig = () => {
    const { config } = this.state;
    return config;
  };

  render() {
    const { downloadingChart, tree } = this.state;

    //For downloading org chart as image or pdf based on id
    const downloadImageId = "download-image";
    const downloadPdfId = "download-pdf";

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div className="zoom-buttons">
          <Link to="/" className="btn btn-outline-primary go-back">
            Go Back
          </Link>
          <button className="btn btn-outline-primary zoom-button" id="zoom-in">
            +
          </button>
          <button className="btn btn-outline-primary zoom-button" id="zoom-out">
            -
          </button>
        </div>
        <div className="download-buttons">
          <div>
            <img src={companyLogo} alt="LURIN Logo" style={{ padding: 5 }} />
          </div>

          <button className="btn btn-outline-primary" id="download-image">
            Download as image
          </button>
          <button className="btn btn-outline-primary" id="download-pdf">
            Download as PDF
          </button>

          {/* <a
            className="github-link"
            href="https://github.com/unicef/react-org-chart"
          >
            Github
          </a> */}
          {downloadingChart && <div>Downloading chart</div>}
        </div>
        {tree && (
          <OrgChart
            tree={tree}
            downloadImageId={downloadImageId}
            downloadPdfId={downloadPdfId}
            onConfigChange={(config) => {
              this.handleOnChangeConfig(config);
            }}
            loadConfig={(d) => {
              let configuration = this.handleLoadConfig();
              if (configuration) {
                return configuration;
              }
              return undefined;
            }}
            downlowdedOrgChart={(d) => {
              this.handleDownload();
            }}
            loadImage={(d) => {
              return Promise.resolve(d);
            }}
            // loadParent={(d) => {
            //   const parentData = this.getParent(d);
            //   return parentData;
            // }}
            loadChildren={(d) => {
              const childrenData = this.getChild(d.id);
              return childrenData;
            }}
          />
        )}
      </div>
    );
  }
}
